export const vimeo = {
    brandName: "vimeo",
    logo:
        "https://prowly-uploads.s3.eu-west-1.amazonaws.com/uploads/PressRoom/563/cover_photo/large_slack-imgs.com.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
