export const youtube = {
    brandName: "youtube",
    logo:
        "https://www.freeiconspng.com/uploads/youtube-logo-png-transparent-image-5.png",
    page: 1,
    search: "",
    category: "",
    queryString: "",
    allCategories: []
};
